import React, { useContext } from 'react';
import classes from "./workRight.module.css";
import WorkRightManual from "../../WorkRightManualDir/WorkRightManual/workRightManual";
import WorkRightEditManual from "../../WorkRightManualDir/WorkRightEditManual/workRightEditManual";
import WorkRightUsers from "../../WorkRightUsersDir/WorkRightUsers/workRightUsers";
import WorkRightUsersAddGroup from "../../WorkRightUsersDir/WorkRightUsersAddGroup/workRightUsersAddGroup";
import WorkRightReports from "../../WorkRightReportsDir/WorkRightReports/workRightReports";
import WorkRightReportsAddGroup from "../../WorkRightReportsDir/WorkRightReportsAddGroup/workRightReportsAddGroup";
import ChangePassword from "../../../ChangePassword/ChangePassword"
import WorkRightStorageAddGroup from "../../WorkRightStorageDir/WorkRightStorageAddGroup/workRightStorageAddGroup";
import WorkRightStorage from "../../WorkRightStorageDir/WorkRightStorage/workRightStorage";
import WorkRightSamples from "../../WorkRightSamplesDir/WorkRightSamples/workRightSamples";
import { modeContext } from "../../../Contexts/contexts";
import DataTransferPage from "../../DataTransfer";
import UsersSettings from "../../UsersSettings/UsersSettings";
import SystemSettings from "../../SystemSettings/SystemSettings";
import SampleType from '../../SampleType/sampleType';
import TypeSampleSourcesGrid from '../../SystemSettings/TypeSampleSources/TypeSampleSourcesGrid';
import TypeSampleSourceForm from '../../SystemSettings/TypeSampleSources/forms/TypeSampleSourceForm';
import SampleSourcesGrid from '../../WorkRightSamplesDir/SampleSources/SampleSourcesGrid';

const WorkRight = ({ sizeX, mode }) => {
  const { widget } = useContext(modeContext);
  const rules = mode.rules;

  return (
    <div className={classes.workRight + ' ' + ((mode.path == 'Образцы' || mode.path == 'Образцы в хранилище' || mode.path === 'Архив') ? classes.workRightRelative : ' ')} style={{ width: 'calc(100% - 2px - ' + sizeX + 'px' }}>
      {
        (
          mode.path === 'Справочники' &&
          mode?.type.mode === 'view' &&
          rules?.catalog?.get &&
          rules?.catalog?.get_info &&
          rules?.catalog?.get_data
        ) &&
        <WorkRightManual sizeX={sizeX}
          setWidget={widget.setWidget}
          mode={mode} />
      }
      {
        (
          mode.path === 'Справочники' &&
          mode?.type.mode === 'edit' &&
          rules?.catalog?.get &&
          rules?.catalog?.get_info &&
          rules?.catalog?.get_data
        ) &&
        <WorkRightEditManual item={mode.type.item} />
      }
      {
        (
          mode.path === 'Пользователи' &&
          (mode?.type.mode === 'view' || mode.type.mode === 'edit') &&
          rules?.user?.get &&
          rules?.user?.get_info
        ) &&
        <WorkRightUsers sizeX={sizeX}
          setWidget={widget.setWidget}
          mode={mode} />
      }
      {
        (
          mode.path === 'Пользователи' &&
          mode?.type.mode === 'edit' &&
          rules?.user?.get &&
          rules?.user?.get_info
        ) &&
        <WorkRightUsersAddGroup sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Отчеты' &&
          (mode?.type.mode === 'view' || mode.type.mode === 'edit') &&
          rules?.report?.get &&
          rules?.report?.get_info
        ) &&
        <WorkRightReports sizeX={sizeX}
          setWidget={widget.setWidget}
          mode={mode} />
      }
      {
        (
          mode.path === 'Отчеты' &&
          mode?.type.mode === 'edit' &&
          rules?.report_group?.get
        ) &&
        <WorkRightReportsAddGroup sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Хранилище' &&
          mode?.type?.mode === 'view' &&
          rules?.storage?.get &&
          rules?.storage?.get_info
        ) &&
        <WorkRightStorage sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }
      {

        (mode.path === 'Хранилище' &&
          mode?.type?.mode === 'edit' &&
          rules?.storage?.get &&
          rules?.storage?.get_info
        ) &&
        <WorkRightStorageAddGroup sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Образцы' &&
          mode?.type?.mode === 'view' &&
          rules?.sample?.get &&
          rules?.sample?.get_info
        ) &&
        <WorkRightSamples sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Архив' &&
          mode?.type?.mode === 'view' &&
          rules?.sample?.get &&
          rules?.sample?.get_info
        ) &&
        <WorkRightSamples sizeX={sizeX}
          mode={mode}
          type={'archived'}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Образцы в хранилище' &&
          mode?.type?.mode === 'view' &&
          rules?.sample?.get &&
          rules?.sample?.get_info
        ) &&
        <WorkRightSamples sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Профиль пользователя' &&
          rules?.user?.get &&
          rules?.user?.get_info
        ) &&
        <ChangePassword mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Импорт'
        ) &&
        <DataTransferPage sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }
      {

        (
          mode.path === 'Настройки' &&
          rules?.user?.get &&
          rules?.user?.get_info
        ) &&
        <UsersSettings mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Общие настройки' &&
          rules?.setting?.get
        ) &&
        <SystemSettings mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Типы источников образцов' &&
          mode?.type?.mode === 'view' &&
          rules?.sample?.get &&
          rules?.sample?.get_info
        ) &&
        <TypeSampleSourcesGrid sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }
      {
        (
          mode.path === 'Новый тип источника образца' || mode.path === 'Редактировать тип источника образца'
        ) &&
        <TypeSampleSourceForm mode={mode} />
      }
      {
        (
          mode.path === 'Карточка образца'
        ) &&
        <SampleType mode={mode} />
      }
      {
        (
          mode.path === 'Источники образцов' &&
          mode?.type?.mode === 'view' &&
          rules?.sample?.get &&
          rules?.sample?.get_info
        ) &&
        <SampleSourcesGrid sizeX={sizeX}
          mode={mode}
          setWidget={widget.setWidget} />
      }

    </div>
  );
};

export default WorkRight;
