import React, { useContext, useEffect } from 'react';
import classes from './TypeSampleSourceForm.module.css';
import baseClasses from '../../../WorkRightUsersDir/WorkRightUserAddMenu/workRightUserAddMenu.module.css'
import { sampleUpsert } from "../../../../../utils/API/api_sample";
import { createTypeSampleSource, updateTypeSampleSource } from "../../../../../utils/API/api_typeSampleSource";
import { modeContext } from "../../../../Contexts/contexts";
import { observer } from "mobx-react-lite";
import { TypeSampleSourceStore } from "../../../../../store/TypeSampleSourceStore";
import { toJS } from "mobx";
import { UserFields } from "./TypeFields/UserFields";
import error from '../../../../../svg/attention-01.svg'

const TypeSampleSourceForm = observer(({
  mode, id = null
}) => {

  const mainContext = React.useContext(modeContext)
  const { setWidget } = mainContext.widget;
  const { typeSampleSourceSelected, setTypeSampleSourceField, clearTypeSampleSourceField } = useContext(TypeSampleSourceStore);

  const [isClickAdd, setIsClickAdd] = React.useState(false)
  const [isChange, setIsChange] = React.useState(false)

  useEffect(() => {
    mode.updateMode();
  }, [typeSampleSourceSelected])

  React.useEffect(() => {
    if (isChange == true)
      setWidget({
        status: 'Ok',
        text: typeSampleSourceSelected.id != null ? `Тип источника образца успешно изменен!` : 'Тип источника образца добавлен успешно!',
        fun: async () => { closeTab(); }
      });

  }, [isChange])

  const cancelClick = () => {
    closeTab();
  }

  const closeTab = () => {
    clearTypeSampleSourceField()
    let tabs = JSON.parse(JSON.stringify(mode.tabs));
    let activeTab = tabs.find((val) => val.id == mode.activeTabId);
    let index = tabs.indexOf(activeTab);
    tabs.splice(index, 1);

    if (tabs.length > 0) {
      mode.setTabs(tabs);
      mode.setActiveTabId(tabs[tabs.length - 1].id);
      mode.setModeByParseTab(tabs[tabs.length - 1]);
    }
    else {
      mode.setTabs(tabs);
      mode.setPath('');
      mode.setType({ mode: 'view', item: '' })
      mode.setActiveTabId(0);
    }
  }

  const addClick = () => {
    setIsClickAdd(true);

    if (!typeSampleSourceSelected.name || !typeSampleSourceSelected.description) return;
    if (typeSampleSourceSelected.id != null)
      return setWidget({
        status: 'Attention',
        text: `Данные типа источника будут изменены`,
        fun: async () => {
          save()
        }
      });

    save();
  }

  const save = () => {
    const typeSampleSourceUpsertAsync = async () => {
      let res;
      if (typeSampleSourceSelected.id != null) {
        res = await updateTypeSampleSource(typeSampleSourceSelected.id, { ...typeSampleSourceSelected, token: mode.token });
      } else res = await createTypeSampleSource({ ...typeSampleSourceSelected, token: mode.token });

      if (!res.success) {
        errorHandler(res.error_code)
      } else {
        setIsChange(true)
      }
    }
    typeSampleSourceUpsertAsync();
  }

  const errorHandler = (code) => {
    setWidget({
      status: 'Error',
      text: ` Код № ${code}.`,
      fun: async () => { }
    });
  }

  return (
    <div className={classes.sampleTypeContainer}>
      <div className={classes.navContainer}>
        <div className={classes.navSampleType}>
          <div className={classes.navTab}>
            Стандартные поля
          </div>
        </div>
      </div>
      <div className={classes.tabsContainer} style={{ height: 'auto' }}>
        <div className={classes.dropDown_container}>
          <div className={classes.lable}>
            Название типа <br /> источника
          </div>

          <div className={classes.inputContainer}>
            <input className={classes.input}
              value={typeSampleSourceSelected.name ?? ''}
              onChange={(e) => setTypeSampleSourceField('name', e.target.value)}
              type='text'
              placeholder='Введите название...' />
          </div>
        </div>
        {
          (!typeSampleSourceSelected?.name?.trim() && isClickAdd) &&
          <div className={baseClasses.dataErrorBlock}>
            <img src={error} alt={'error'} />
            <div className={baseClasses.dataError}>Поле обязательно для заполнения</div>
          </div>
        }
        <div className={classes.dropDown_container}>
          <div className={classes.lable}>
            Описание
          </div>
          <div className={classes.dropDown}>
            <textarea className={classes.textarea}
              placeholder={'Описание объекта'}
              value={typeSampleSourceSelected.description ?? ''}
              onChange={(val) => setTypeSampleSourceField('description', val.target.value)}></textarea>
          </div>
        </div>
        {
          (!typeSampleSourceSelected?.description?.trim() && isClickAdd) &&
          <div className={baseClasses.dataErrorBlock}>
            <img src={error} alt={'error'} />
            <div className={baseClasses.dataError}>Поле обязательно для заполнения</div>
          </div>
        }
      </div>
      <div className={classes.navContainer}>
        <div className={classes.navSampleType}>
          <div className={classes.navTab}>
            Пользовательские поля
          </div>
        </div>

      </div>
      <div className={classes.tabsContainer + ' ' + classes.userFieldsTabsContainer}>
        {
          <UserFields mode={mode}
            setTypeSampleSourceField={setTypeSampleSourceField}
            typeSampleSourceSelected={typeSampleSourceSelected} />
        }
        <div className={classes.footer}>
          {

            <button className={`button_default save_style`} onClick={() => addClick()}>{typeSampleSourceSelected.id == null ? 'Добавить' : 'Редактировать'}</button>
          }
          <button className={`button_default cancel_style`} style={{ marginLeft: !typeSampleSourceSelected.editable && typeSampleSourceSelected.editable != null ? '0px' : '20px' }} onClick={() => cancelClick()}>Отмена</button>
        </div>
      </div>
    </div>
  );
});

export default TypeSampleSourceForm;