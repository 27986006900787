import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction, toJS } from "mobx";
import { getSample, getTypeSampleById } from "../utils/API/api_sample";
import { getTypeSampleSourceById } from "../utils/API/api_typeSampleSource";

class AppStore {
  typeSampleSourceSelected = {
    id: null,
    name: "",
    description: "",
    is_active: true,
    updated_at: null,
    sources_count: null,
    user_fields_count: 0,
    user_fields: [],

    currentPage: 1,
    limit: 10,
    sortField: { name: "asc" },
    searchValue: "",

    pagination: {},

    selectedArray: [],
    globalFields: [],
    deleted_fields: [],
  };

  constructor() {
    makeAutoObservable(this, {
      typeSampleSourceSelected: observable,
    });
  }

  setTypeSampleSourceId = async (id, token) => {
    const res = await getTypeSampleSourceById(token, id);
    for (let key of Object.keys(res.result)) {
      this.typeSampleSourceSelected[key] = res.result[key];
    }
  };

  getStore = () => {
    return {
      typeSampleSourceSelected: this.typeSampleSourceSelected,
    };
  };

  setStore = (data) => {
    if (data) {
      this.typeSampleSourceSelected = data.typeSampleSourceSelected;
    }
  };

  clearTypeSampleSourceField = () => {
    this.typeSampleSourceSelected = {
      id: null,
      name: "",
      description: "",
      is_active: true,
      updated_at: null,
      sources_count: null,
      user_fields_count: 0,
      user_fields: [],

      currentPage: 1,
      limit: 10,
      sortField: { name: "asc" },
      searchValue: "",

      pagination: {},

      selectedArray: [],
      globalFields: [],
      deleted_fields: [],
    };
  };

  setTypeSampleSourceField = (key, val) => {
    let copyObj = JSON.parse(JSON.stringify(this.typeSampleSourceSelected));
    copyObj[key] = val;
    this.typeSampleSourceSelected = copyObj;
  };
}

export const TypeSampleSourceStore = createContext(new AppStore());
