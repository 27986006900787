import { address_server, address_server_short } from "../../config";
import { error } from "../api";
import { transliterate } from "../../helpers/utils";
import dayjs from "dayjs";
import { toJS } from "mobx";

export async function getTypeSampleSources(token, page, parameters) {
  let optionalParams = "";
  if (parameters?.search) {
    optionalParams += "&search=" + parameters.search;
  }

  if (parameters?.sorting) {
    optionalParams += "&sorting=" + parameters.sorting;
  }

  const res = await (
    await fetch(
      address_server +
        "type-sample-sources?token=" +
        token +
        "&page=" +
        page +
        optionalParams
    )
  ).json();

  if (res.success) {
    return res;
  }
  error("getTypeSampleSources", res);
  return res;
}

export async function getTypeSampleSourceById(token, id) {
  let res = "";
  res = await (
    await fetch(
      address_server + "type-sample-sources/" + id + "?token=" + token
    )
  ).json();

  if (res.success) {
    return res;
  }
  error("getTypeSampleSources", res);
  return res;
}

export async function createTypeSampleSource(params) {
  const data = {
    name: params.name?.trim(),
    description: params.description?.trim(),
    user_fields: [],
  };

  if (params.user_fields && params.user_fields.length) {
    params.user_fields.map((value, index) =>
      data.user_fields.push({ id: value.id_user_field, order: index })
    );
  }
  return (
    await fetch(
      address_server + "type-sample-sources" + "?token=" + params.token,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  ).json();
}

export async function updateTypeSampleSource(id, params) {
  const data = {
    name: params.name?.trim(),
    description: params.description?.trim(),
    user_fields: [],
  };

  if (params.user_fields && params.user_fields.length) {
    params.user_fields.map((value, index) =>
      data.user_fields.push({ id: value.id_user_field, order: index })
    );
  }
  return (
    await fetch(
      address_server + "type-sample-sources/" + id + "?token=" + params.token,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  ).json();
}

export async function deleteTypeSampleSource(token, id) {
  return (
    await fetch(
      address_server + "type-sample-sources/" + id + "?token=" + token,
      {
        method: "DELETE",
      }
    )
  ).json();
}

export async function getTypeSampleSourcesList(token) {
  const res = await (
    await fetch(address_server + "type-sample-sources-list?token=" + token)
  ).json();

  if (res.success) {
    return res;
  }
  error("getTypeSampleSourcesList", res);
  return res;
}

export async function toggleActivateTypeSampleSource(token, id) {
  return (
    await fetch(
      address_server +
        "type-sample-sources/" +
        id +
        "/toggle-activate?token=" +
        token,
      {
        method: "POST",
      }
    )
  ).json();
}
