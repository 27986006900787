import { address_server, address_server_short } from "../../config";
import { error } from "../api";
import { transliterate } from "../../helpers/utils";
import dayjs from "dayjs";
import { toJS } from "mobx";

export async function getSampleSources(token, page, parameters) {
  let optionalParams = "";
  if (parameters?.search) {
    optionalParams += "&search=" + parameters.search;
  }

  if (parameters?.sorting) {
    optionalParams += "&sorting=" + parameters.sorting;
  }

  if (parameters?.columns) {
    optionalParams += "&columns=" + JSON.stringify(parameters.columns);
  }

  const res = await (
    await fetch(
      address_server +
        "sample-sources?token=" +
        token +
        "&page=" +
        page +
        optionalParams
    )
  ).json();

  if (res.success) {
    return res;
  }
  error("getSampleSources", res);
  return res;
}

export async function getSampleSourceById(token, id) {
  let res = "";
  res = await (
    await fetch(address_server + "sample-sources/" + id + "?token=" + token)
  ).json();

  if (res.success) {
    return res;
  }
  error("getSampleSourceById", res);
  return res;
}

export async function createSampleSource(params) {
  const data = {
    name: params.name?.trim(),
    description: params.description?.trim(),
    type: params.type,
    user_fields: [],
  };

  if (params.user_fields && params.user_fields.length) {
    params.user_fields.map((value) =>
      data.user_fields.push({ id: value.id_field, value: value.value })
    );
  }
  return (
    await fetch(address_server + "sample-sources" + "?token=" + params.token, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).json();
}

export async function updateSampleSource(id, params) {
  const data = {
    name: params.name?.trim(),
    description: params.description?.trim(),
    type: params.type,
    user_fields: [],
  };

  if (params.user_fields && params.user_fields.length) {
    params.user_fields.map((value) =>
      data.user_fields.push({ id: value.id_field, value: value.value })
    );
  }
  return (
    await fetch(
      address_server + "sample-sources/" + id + "?token=" + params.token,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  ).json();
}

export async function deleteSampleSource(token, id) {
  return (
    await fetch(address_server + "sample-sources/" + id + "?token=" + token, {
      method: "DELETE",
    })
  ).json();
}

export async function toggleActivateSampleSource(token, id) {
  return (
    await fetch(
      address_server +
        "sample-sources/" +
        id +
        "/toggle-activate?token=" +
        token,
      {
        method: "POST",
      }
    )
  ).json();
}

export async function getSampleSourcesList(token, search) {
  let optionalParams = "";
  if (search) {
    optionalParams += "&search=" + search;
  }

  const res = await (
    await fetch(
      address_server +
        "sample-sources/get-list?token=" +
        token +
        optionalParams,
      {
        method: "POST",
      }
    )
  ).json();

  if (res.success) {
    return res;
  }
  error("getSampleSourcesList", res);
  return res;
}
