import React, { useContext, useEffect, useState } from 'react';
import WorkRightHeader from "../../WorkRightCommon/WorkRightHeader/workRightHeader";
import classes from "../../WorkRightManualDir/WorkRightManual/workRightManual.module.css";
import WorkRightSelectPage from "../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import WorkRightTable from "../../WorkRightCommon/WorkRightTable/workRightTable";
import { getTypeSampleSources } from "../../../../utils/API/api_typeSampleSource";
import WorkRightStorageViewMenu from "../../WorkRightStorageDir/WorkRightStorageViewMenu/workRightStorageViewMenu";
import { observer } from "mobx-react-lite";
import { StorageStore } from "../../../../store/StorageStore";
import ModalMoveComponent from "../../../Widgets/Modal/ModalMoveComponent";
import { getStorages } from "../../../../utils/API/api_storage";
import { Store } from "../../../../store/Store";
import { TypeSampleSourceStore } from "../../../../store/TypeSampleSourceStore";
import { modeContext } from '../../../Contexts/contexts';

const tableColumns = [
  {
    field: "name",
    name: "Название источника образца",
    showColumn: true,
    sort: true,
    type: "is_active",
  },
  {
    field: "description",
    name: "Описание",
    showColumn: true,
    sort: true,
    type: "string",
  },
  {
    field: "user_fields_count",
    name: "Поля пользователя",
    showColumn: true,
    sort: false,
    type: "string",
  },
  {
    field: "sources_count",
    name: "Всего",
    showColumn: true,
    sort: false,
    type: "string",
  },
  {
    field: "is_active",
    name: "Включено",
    showColumn: true,
    sort: false,
    type: "switch",
  },
  {
    field: "updated_at",
    name: "Дата последнего обновления",
    showColumn: true,
    sort: false,
    type: "date",
  },
  {
    field: "user_fields",
    systemColumn: true,
  },
];

const TypeSampleSourcesGrid = observer(({ setWidget, sizeX, mode, type = null }) => {
  const storageStore = useContext(StorageStore);
  const { loadTree, selectedSample } = React.useContext(Store);
  const typeSampleSourceStore = React.useContext(TypeSampleSourceStore);
  const modeMain = React.useContext(modeContext);

  const [countInPage, setCountInPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageAll, setPageAll] = useState(10);
  const [countAll, setCountAll] = useState(0);
  const [filter, setFilter] = useState('');
  const [sortField, setSortField] = useState({ type: '', field: '' });
  const [structHeader, setStructHeader] = useState({
    name: true,
    search: true,
    add: true,
    filter: true,
    print: false,
    wrap: true
  });
  const [isSearchBarcode, setIsSearchBarcode] = useState(false);

  const [rows, setRows] = useState([]);
  const [struct, setStruct] = useState([]);
  const [selectItem, setSelectItem] = useState();
  const [rowsButton, setRowsButton] = useState({
    print: false,
    copy: false,
    edit: true,
    delete: true,
    activate: true
  });
  const [isLoadAfterError, setIsLoadAfterError] = useState(true);
  const [filterFieldsSave, setFilterFieldsSave] = useState();
  const [visiblePrintMenu, setVisiblePrintMenu] = useState(false);

  const [visibleAdd, setVisibleAdd] = useState(false);

  useEffect(() => {
    setStruct(tableColumns);
  }, []);

  const rules = mode.rules ?? {};

  React.useEffect(() => {
    setStructHeader({
      name: true,
      search: true,
      add: rules?.sample?.upsert,
      filter: true,
      print: true,
      wrap: true
    });
    setRowsButton({
      edit: rules?.sample?.upsert,
      delete: rules?.sample?.delete,
      activate: true
    })
  }, [mode]);

  useEffect(() => {
    if (isLoadAfterError)
      loadTableData();
  }, [mode, filter, page, sortField, selectedSample, filterFieldsSave])

  useEffect(() => {
    if (visibleAdd) navToAddOrEditForm('new');
  }, [visibleAdd])

  useEffect(() => {
    if (selectItem) navToAddOrEditForm('edit');
  }, [selectItem])

  const navToAddOrEditForm = async (modeOpen) => {
    if (modeOpen == 'new') {
      typeSampleSourceStore.clearTypeSampleSourceField()
    }

    if (modeOpen == 'edit') {
      await typeSampleSourceStore.setTypeSampleSourceId(selectItem.id, mode.token)
    }

    let newContext = JSON.parse(JSON.stringify(modeMain));
    const newTab = {
      name: modeOpen == 'new' ? 'Новый тип источника образца' : 'Редактировать тип источника образца',
      path: modeOpen == 'new' ? 'Новый тип источника образца' : 'Редактировать тип источника образца',
      mode: newContext,
      id: new Date().getTime(),
    };

    localStorage.setItem('active_tabs', newTab.id);
    mode.setTabs([...mode.tabs, newTab]);
    mode.setActiveTabId(newTab.id);
    mode.setVisibleAdd(false);
    mode.setPath(modeOpen == 'new' ? 'Новый тип источника образца' : 'Редактировать тип источника образца');
  }

  const changeColumns = (newColumns) => {
    setStruct(newColumns);
  }

  const loadTableData = async (filterFields = null) => {
    const { result } = await getTypeSampleSources(mode.token, page, {
      search: filter,
      sorting: sortField.type != '' ? JSON.stringify(sortField) : '',
    });
    const items = result.data;
    if (items) {
      setStruct(tableColumns);
      setCountAll(result.pagination.items_count);
      setRows(items.map((row) => ({ id: row.id, setting: null, value: row })));
      setPageAll(result.pagination.pages_count);

    } else {
      setWidget({
        status: 'Error',
        text: result.error_code,
        fun: async () => {
          setIsLoadAfterError(false)
        }
      })
    }
  }
  return (
    <div style={{ height: '100%' }}>
      <WorkRightHeader setVisibleAdd={setVisibleAdd}
        activeData={mode.type.item}
        struct={structHeader}
        setVisiblePrintMenu={setVisiblePrintMenu}
        filter={filter}
        setIsSearchBarcode={setIsSearchBarcode}
        isSearchBarcode={isSearchBarcode}
        setFilter={setFilter} />

      <div className={classes.workRightManual_countRows}>Всего найдено: {countAll}</div>

      <WorkRightSelectPage allCount={pageAll}
        selectItem={page}
        setSelectItem={setPage} />
      <WorkRightTable mode={mode}
        struct={struct}
        rows={rows}
        visiblePrintMenu={visiblePrintMenu}
        setVisiblePrintMenu={setVisiblePrintMenu}
        setRows={setRows}
        rowsButton={rowsButton}
        setVisibleAdd={setVisibleAdd}
        setSelectItem={setSelectItem}
        setWidget={setWidget}
        sortField={sortField}
        showColumnSelector={true}
        setSortField={setSortField}
        fromOther={true}
        page={page}
        loadTSamples={() => { }}
        showExpadedRow={true}
        changeColumns={changeColumns}
      />
    </div>
  );
});

export default TypeSampleSourcesGrid;
