import { createContext } from "react";
import { makeAutoObservable, observable } from "mobx";
import { getSampleSourceById } from "../utils/API/api_sampleSource";

class AppStore {
  sampleSourceSelected = {
    id: null,
    name: "",
    type: "",
    id_type: null,
    description: "",
    is_active: true,
    updated_at: null,
    samples_count: 0,
    user_fields: [],
  };

  constructor() {
    makeAutoObservable(this, {
      sampleSourceSelected: observable,
    });
  }

  setSampleSourceId = async (id, token) => {
    const res = await getSampleSourceById(token, id);
    for (let key of Object.keys(res.result)) {
      this.sampleSourceSelected[key] = res.result[key];
    }
  };

  getStore = () => {
    return {
      sampleSourceSelected: this.sampleSourceSelected,
    };
  };

  setStore = (data) => {
    if (data) {
      this.sampleSourceSelected = data.sampleSourceSelected;
    }
  };

  clearSampleSourceField = () => {
    this.sampleSourceSelected = {
      id: null,
      name: "",
      type: "",
      id_type: null,
      description: "",
      is_active: true,
      updated_at: null,
      samples_count: 0,
      user_fields: [],
    };
  };

  setSampleSourceField = (key, val) => {
    let copyObj = JSON.parse(JSON.stringify(this.sampleSourceSelected));
    copyObj[key] = val;
    this.sampleSourceSelected = copyObj;
  };
}

export const SampleSourceStore = createContext(new AppStore());
