import React, { useRef } from 'react';
import localClasses from './UserFields.module.css'
import confirmSVG from '../../../../../../svg/button_on-01.svg'
import { COLORS } from '../../../../../../config';
import Icon from '../../../../../../helpers/components/Icon';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableLeftRow } from './TableLeftRow';
import { useCallback } from 'react';
import { getListTypeFields } from '../../../../../../utils/API/api_list';
import searchSVG from '../../../../../../svg/serch-01.svg';

import { modeContext } from "../../../../../Contexts/contexts";
import { getAllUserFields } from "../../../../../../utils/API/api_sample";
import WorkRightSelectPage from "../../../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";

import { observe, toJS } from "mobx";


import { TypeSampleSourceStore } from "../../../../../../store/TypeSampleSourceStore";
import { observer } from "mobx-react-lite";

export const UserFields = observer(({
  mode,
  setTypeSampleSourceField
}) => {
  const { typeSampleSourceSelected } = React.useContext(TypeSampleSourceStore);
  const mainMode = React.useContext(modeContext);
  const { setWidget } = mainMode.widget;

  const ref = useRef(null);
  const [fieldTypes, setFieldTypes] = React.useState([]);
  const [isOpenDDAddFiled, setIsOpenDDAddFiled] = React.useState(false);
  const [modalAddOpened, setModalAddOpened] = React.useState(null)
  const [idType, setIdType] = React.useState();
  const [isAddSuccess, setIsAddSuccess] = React.useState(false);
  const [fieldDeleted, setFieldDeleted] = React.useState([]);

  React.useEffect(() => {
    const loadAllTypes = async () => {
      let res = await getListTypeFields(mode.token);
      setFieldTypes(res);
    }
    loadAllTypes();
  }, []);

  React.useEffect(() => {
    if (typeSampleSourceSelected.searchValue != '') {
      setTypeSampleSourceField('currentPage', 1);
    }
  }, [typeSampleSourceSelected.searchValue]);


  React.useEffect(() => {
    const loadAllUserFields = async () => {
      let res = await getAllUserFields(mode.token,
        typeSampleSourceSelected.currentPage,
        typeSampleSourceSelected.limit,
        typeSampleSourceSelected.sortField != null ? JSON.stringify(typeSampleSourceSelected.sortField) : '',
        typeSampleSourceSelected.searchValue)

      setTypeSampleSourceField('globalFields', res.user_fields.map(val => { return { ...val, isCheck: false, isMoreInfo: false, deletable: true } }))
      setTypeSampleSourceField('pagination', res.pagination)
    }
    loadAllUserFields();
  }, [typeSampleSourceSelected.currentPage, typeSampleSourceSelected?.sortField.name, typeSampleSourceSelected?.sortField.type, typeSampleSourceSelected.searchValue, isAddSuccess]);


  React.useEffect(() => {
    window.onclick = (event) => {
      if (event.target.contains(ref.current)
        && event.target !== ref.current) {
        setIsOpenDDAddFiled(false)
      }
    }
  }, []);

  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = typeSampleSourceSelected.user_fields[dragIndex]
      const hoverItem = typeSampleSourceSelected.user_fields[hoverIndex]
      // Swap places of dragItem and hoverItem in the pets array
      const updatedData = [...typeSampleSourceSelected.user_fields]
      updatedData[dragIndex] = hoverItem
      updatedData[hoverIndex] = dragItem
      setTypeSampleSourceField('user_fields', updatedData)
    },
    [typeSampleSourceSelected.user_fields],
  );

  const openAddFiled = () => {
    setIsOpenDDAddFiled(!isOpenDDAddFiled);
  }

  const onSearchFields = (val) => {
    setTypeSampleSourceField('searchValue', val.target.value);
  }

  const setNewGlobalFields = (index, key, value) => {
    const obj = JSON.parse(JSON.stringify(typeSampleSourceSelected.globalFields));
    obj[index][key] = value;
    setTypeSampleSourceField('globalFields', obj);
  }

  const setNewLocalFields = (index, key, value) => {
    const obj = JSON.parse(JSON.stringify(typeSampleSourceSelected.user_fields));
    obj[index][key] = value;
    setTypeSampleSourceField('user_fields', obj);
  }

  const addInLocalField = () => {
    let newlocalFields = [...typeSampleSourceSelected.user_fields, ...typeSampleSourceSelected.selectedArray.filter(val => !typeSampleSourceSelected.user_fields.find(value => value.name == val.name))];
    setTypeSampleSourceField('selectedArray', []);
    setTypeSampleSourceField('user_fields', newlocalFields.map(val => { return { ...val, isCheck: false } }));
    setTypeSampleSourceField('globalFields', typeSampleSourceSelected.globalFields.map(val => { return { ...val, isCheck: false } }));
  }

  const dropFromLocalField = () => {
    setWidget({
      status: 'Sure',
      text: `Вы действительно хотите удалить ${typeSampleSourceSelected.user_fields.filter(val => val.isCheck).length == 1 ? 'выбранное поле' : 'выбранные поля'} ?`,
      fun: async () => {
        setTypeSampleSourceField('deleted_fields', typeSampleSourceSelected.user_fields.filter(val => val.isCheck == true))
        setTypeSampleSourceField('user_fields', typeSampleSourceSelected.user_fields.filter(val => val.isCheck !== true))
      }
    });
  }

  const setSelectItem = (val) => {
    setTypeSampleSourceField('currentPage', val);
  }

  const checkParse = (val) => {
    let newVal;
    try {
      newVal = JSON.parse(val)
    } catch {
      newVal = JSON.parse(val)
    }
    if (Array.isArray(newVal))
      return newVal
    else
      return [newVal]
  }

  return (
    <div className={localClasses.leftTabContainer}>
      <DndProvider backend={HTML5Backend} >
        <div className={localClasses.containerHeaderButton}>
          <div className={localClasses.leftHeaderButton}>
          </div>
          <div className={localClasses.rightHeaderButton}>
            <div className={localClasses.btnAddContainer} onClick={() => openAddFiled()}>
              <div className={localClasses.textAddContainer}>Добавить поле</div>
              <div className={localClasses.iconAddContainer}>
                <Icon
                  color={COLORS.color_hover}
                  src={'../../../../svg/arrow-01.svg'}
                  width={'12px'}
                  height={'12px'}
                  className={`${localClasses.arrowAddIcon} ${isOpenDDAddFiled ? localClasses.flippedArrowIcon : ''}`}
                />
              </div>
              <div ref={ref} className={`${localClasses.selectTextDD} ${isOpenDDAddFiled ? '' : localClasses.selectTextDDopacity}`}>
                {
                  fieldTypes && fieldTypes.map((val, index) =>
                    <div key={index} className={localClasses.selectTextItemDD} onClick={() => { setModalAddOpened(true); setIdType(val.id) }}>{val.name}</div>
                  )
                }

              </div>
            </div>
            <div className={localClasses.searchContainer}>
              <div className={localClasses.WorkLeftHeader_search}>
                <img className={localClasses.WorkLeftHeader_search_svg} src={searchSVG} alt={'search'} />
                <input className={localClasses.WorkLeftHeader_search_input}
                  placeholder={'Отобрать'}
                  value={typeSampleSourceSelected.searchValue}
                  onChange={(val) => onSearchFields(val)} />
              </div>
            </div>
          </div>
        </div>
        <div className={localClasses.containerHeaderTotal}>
          <div style={{ marginTop: '20px', width: '60%' }}>Добавлено: {typeSampleSourceSelected.user_fields?.length}</div>
          <div style={{ marginTop: '20px' }}>
            Найдено: {typeSampleSourceSelected.pagination.total}
          </div>
        </div>
        <div className={localClasses.tables}>
          <div className={localClasses.tableLeftContainer}>
            <div className={localClasses.tableLeft}>
              <div className='head'>
                <div className={localClasses.tableLeftRowHead}>
                  <div className={localClasses.iconContainer}>
                    <div className={localClasses.imgAdd} onClick={() => dropFromLocalField()}>
                      <Icon
                        color={COLORS.bg_primary}
                        src={'../../../../svg//close-01.svg'}
                        width={'15px'}
                        height={'15px'}
                      />
                      <div className={localClasses.tooltip}>
                        Удалить поле
                      </div>
                    </div>

                  </div>
                  <div className={localClasses.leftRowName}>
                    Название поля

                  </div>
                  <div className={localClasses.leftRowType}>
                    Тип
                  </div>
                  <div className={localClasses.leftRowReq}>
                    Обязательное
                  </div>
                </div>
              </div>
              <div className={localClasses.tableLeftBody}>
                {
                  typeSampleSourceSelected.user_fields?.length ? (typeSampleSourceSelected.user_fields.map(val => { return { ...val, isChecked: false } })).map((item, index) =>
                    <TableLeftRow setNewLocalFields={setNewLocalFields}
                      row={item}
                      movePetListItem={movePetListItem}
                      index={index}
                      localClasses={localClasses}
                      key={item.name + item.type}
                    />
                  )
                    : <div className={localClasses.tableLeftRow + ' ' + localClasses.tableLeftRowClear}>
                      Выберите поле для заполнения
                    </div>
                }

              </div>
            </div>
          </div>
          <div className={localClasses.tableRightContainer}>
            <div className={localClasses.tableRight}>
              <div className={localClasses.tableLeftRowHead}>
                <div className={localClasses.iconContainer}>
                  <div className={localClasses.imgAdd} onClick={() => addInLocalField()}>
                    <Icon
                      color={COLORS.bg_primary}
                      src={'../../../../svg/add-01.svg'}
                      width={'15px'}
                      height={'15px'}
                    />
                    <div className={localClasses.tooltip}>
                      Выбрать поле
                    </div>
                  </div>
                </div>
                <div className={localClasses.collapsContainer}>
                </div>
                <div className={localClasses.rightRowName}>
                  <div style={{ padding: 0 }}>Название поля</div>
                  {
                    typeSampleSourceSelected.sortField == null || typeSampleSourceSelected.sortField.name == undefined
                      ? <div className={localClasses.workRightTableSortBlock}>
                        <div className={localClasses.workRightTableSortUpNot} onClick={() => {
                          setTypeSampleSourceField('sortField', { "name": "desc" })
                        }} />
                        <div className={localClasses.workRightTableSortDownNot} onClick={() => {
                          setTypeSampleSourceField('sortField', { "name": "asc" })
                        }} />
                      </div>
                      : (typeSampleSourceSelected.sortField.name == 'asc'
                        ?
                        <div className={localClasses.workRightTableSortBlock}>
                          <div style={{ padding: 0 }}> </div>
                          <div className={localClasses.workRightTableSortDown} onClick={() => {
                            setTypeSampleSourceField('sortField', { "name": "desc" })
                          }}></div>
                        </div>

                        : <div className={localClasses.workRightTableSortBlock}>

                          <div className={localClasses.workRightTableSortUp} onClick={() => {
                            setTypeSampleSourceField('sortField', { "name": "asc" })
                          }}></div>
                          <div style={{ padding: 0 }}></div>
                        </div>)

                  }
                </div>
                <div className={localClasses.rightRowType}>
                  <div style={{ padding: 0 }}>Тип</div>
                  {
                    typeSampleSourceSelected.sortField == null || typeSampleSourceSelected.sortField.type == undefined
                      ? <div className={localClasses.workRightTableSortBlock}>
                        <div className={localClasses.workRightTableSortUpNot} onClick={() => {
                          setTypeSampleSourceField('sortField', { "type": "desc" })
                        }} />
                        <div className={localClasses.workRightTableSortDownNot} onClick={() => {
                          setTypeSampleSourceField('sortField', { "type": "asc" })
                        }} />
                      </div>
                      : (typeSampleSourceSelected.sortField.type == 'asc'
                        ?
                        <div className={localClasses.workRightTableSortBlock}>
                          <div style={{ padding: 0 }}> </div>
                          <div className={localClasses.workRightTableSortDown} onClick={() => {
                            setTypeSampleSourceField('sortField', { "type": "desc" })
                          }}></div>
                        </div>
                        :
                        <div className={localClasses.workRightTableSortBlock}>
                          <div className={localClasses.workRightTableSortUp} onClick={() => {
                            setTypeSampleSourceField('sortField', { "type": "asc" })
                          }}></div>
                          <div style={{ padding: 0 }}></div>
                        </div>)

                  }
                </div>
              </div>
              <div className={localClasses.tableRightBody}>
                {
                  typeSampleSourceSelected?.globalFields?.map((row, index) => (
                    <div key={index} className={localClasses.tableRightRowContainer}>
                      <div className={localClasses.tableRightRow} >
                        <div className={localClasses.chackboxContainer}>
                          <div className={(row.isCheck ? localClasses.bgcCkeck : '') + ' ' + localClasses.checkbox + ' ' + ((typeSampleSourceSelected.id && row.required) ? localClasses.isDisable : '')}
                            onClick={() => {
                              if (typeSampleSourceSelected.id && !row.required) {
                                if (typeSampleSourceSelected.selectedArray?.find(val => val.id_user_field == row.id_user_field)) {
                                  let copyArray = JSON.parse(JSON.stringify(typeSampleSourceSelected.selectedArray))
                                  setTypeSampleSourceField('selectedArray', copyArray.filter(val => val.id_user_field != row.id_user_field))
                                } else {
                                  setTypeSampleSourceField('selectedArray', [...typeSampleSourceSelected.selectedArray ?? [], row])
                                }
                                setNewGlobalFields(index, 'isCheck', !row.isCheck)
                              }
                              else if (!typeSampleSourceSelected.id) {
                                if (typeSampleSourceSelected.selectedArray?.find(val => val.id_user_field == row.id_user_field)) {
                                  let copyArray = JSON.parse(JSON.stringify(typeSampleSourceSelected.selectedArray))
                                  setTypeSampleSourceField('selectedArray', copyArray.filter(val => val.id_user_field != row.id_user_field))
                                } else {
                                  setTypeSampleSourceField('selectedArray', [...typeSampleSourceSelected.selectedArray ?? [], row])
                                }
                                setNewGlobalFields(index, 'isCheck', !row.isCheck)
                              }
                            }
                            }
                          >
                            {
                              typeSampleSourceSelected.selectedArray?.find(val => val.id_user_field == row.id_user_field) &&
                              <Icon
                                color={COLORS.color_hover2}
                                src={confirmSVG}
                                width={'12px'}
                                height={'12px'}
                              />
                            }
                          </div>
                        </div>
                        <div className={localClasses.collapsContainer}>
                          <div className={localClasses.collaps} onClick={() => setNewGlobalFields(index, 'isMoreInfo', !row.isMoreInfo)}>
                            {
                              !row.isMoreInfo ?
                                <Icon
                                  color={COLORS.text_secondary}
                                  src={'../../../../svg/add-01.svg'}
                                  width={'10px'}
                                  height={'10px'}
                                />
                                :
                                <div className={localClasses.minusInCollaps}></div>
                            }
                            <div className={localClasses.tooltip + ' ' + localClasses.tooltipInfo}>
                              Раскрыть
                            </div>
                          </div>
                        </div>
                        <div className={localClasses.rightRowName}>
                          <span className={localClasses.center}>{row.name}</span>
                        </div>
                        <div className={localClasses.rightRowType}>
                          {row.type_fields.name}
                        </div>
                      </div>
                      {
                        row.isMoreInfo && <div className={localClasses.moreInfoRow}>
                          Значения: <span className={localClasses.moreInfoRowTextNoraml}>
                            {
                              (checkParse(row?.setting) && checkParse(row?.setting).length > 0) ? checkParse(row?.setting)?.map((val, i) => {
                                switch (row?.type_fields?.type_name) {
                                  case "finance":
                                    return `Валюта - ${val.name}`
                                  case "file":
                                    return `Максимальный размер - ${val.max_size}, типы файлов: ${val.type.map(val => ` ${val} `)}`
                                  case "progress": {
                                    if (i == 0)
                                      return `От ${checkParse(row?.setting).find(item => item.param == 'min').value}  до ${checkParse(row?.setting).find(item => item.param == 'max').value}`
                                    else
                                      return ''
                                  }
                                  case "location":
                                    return `Адресс - ${val.address}, широта - ${val.latitude}, долгота - ${val.longitude}`
                                  case "float":
                                    return `Округление - ${val.rounding}`
                                  case "image":
                                    return `Максимальный размер - ${val.max_size}, длина - ${val.width}, высота - ${val.height}`
                                  case "range":
                                    return `${checkParse(row?.setting)[i + 1] ? `От ${val.value}  ` : `до ${val.value}`}`
                                  case "date":
                                    return `${val.time ? 'С учетом времени' : 'Без учета времени'}`
                                  case "select_check":
                                    return `${val.value == ('0' || '1') ? (val.value == ('0' || 0) ? 'Один вариант выбора' : 'Несколько вариантов выбора') : val.value}${checkParse(row?.setting)[i + 1] ? ', ' : ''}`
                                  case "switch_variants":
                                  case "select":
                                    return `${val.param}${checkParse(row?.setting)[i + 1] ? ', ' : ''}`
                                  default:
                                    {
                                      if (i == 0)
                                        return 'Параметры не заданы'
                                      else
                                        return ''
                                    }
                                }
                              })
                                : 'Параметры не заданы'
                            }
                          </span>
                          <br />
                          Used By: <span className={localClasses.moreInfoRowTextNoraml}>{row.type_samples.map(val => { return `${val}, ` })}</span>
                        </div>
                      }
                    </div>
                  ))
                }

              </div>
            </div>
            <WorkRightSelectPage allCount={typeSampleSourceSelected.pagination.pages} selectItem={typeSampleSourceSelected.currentPage} setSelectItem={setSelectItem}></WorkRightSelectPage>
          </div>
        </div>

      </DndProvider>
      {/* {
        modalAddOpened && <AddFieldModal setModalOpened={setModalAddOpened} mode={mode} idType={idType} isAddSuccess={isAddSuccess} setIsAddSuccess={setIsAddSuccess} />
      } */}
    </div>
  );
})