import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import confirmSVG from "../../../../../../svg/button_on-01.svg";
import Icon from "../../../../../../helpers/components/Icon";
import { COLORS } from "../../../../../../config";

export const TableLeftRow = ({ movePetListItem, index, localClasses, row, setNewLocalFields }) => {

  const [{ isDragging }, dragRef] = useDrag({
    type: 'item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  });

  const [spec, dropRef] = useDrop({
    accept: 'item',
    hover: (item, monitor) => {
      const dragIndex = item.index
      const hoverIndex = index
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      movePetListItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  const onSelect = () => {
    setNewLocalFields(index, 'isCheck', !row.isCheck);
  }

  return (
    <div onClick={() => {
      if (row.deletable)
        onSelect()
    }} ref={dragDropRef} className={localClasses.tableLeftRow + ' ' + (isDragging ? localClasses.opacity : ' ')}>
      <div className={localClasses.chackboxContainer}>
        <div className={(row.isCheck ? localClasses.bgcCkeck : '') + ' ' + localClasses.checkbox + ' ' + (row.deletable ? ' ' : localClasses.isDisable)}>
          {
            row.isCheck &&
            <Icon
              color={COLORS.color_hover2}
              src={confirmSVG}
              width={'12px'}
              height={'12px'}
            />
          }
        </div>
      </div>
      <div className={localClasses.leftRowName}>
        {row?.name}
      </div>
      <div className={localClasses.leftRowType}>
        {row?.type?.name ?? row?.type_fields?.name ?? ''}
      </div>
      <div className={localClasses.leftRowReq}>
        {row.required ? 'Да' : 'Нет'}
      </div>
    </div>
  )
}