import React, { useContext, useEffect, useState } from 'react';
import WorkRightHeader from "../../WorkRightCommon/WorkRightHeader/workRightHeader";
import classes from "../../WorkRightManualDir/WorkRightManual/workRightManual.module.css";
import WorkRightSelectPage from "../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import WorkRightTable from "../../WorkRightCommon/WorkRightTable/workRightTable";
import { getTypeSampleSources, getTypeSampleSourcesList } from "../../../../utils/API/api_typeSampleSource";
import WorkRightStorageViewMenu from "../../WorkRightStorageDir/WorkRightStorageViewMenu/workRightStorageViewMenu";
import { observer } from "mobx-react-lite";
import { StorageStore } from "../../../../store/StorageStore";
import ModalMoveComponent from "../../../Widgets/Modal/ModalMoveComponent";
import { getStorages } from "../../../../utils/API/api_storage";
import { Store } from "../../../../store/Store";
import { SampleSourceStore } from "../../../../store/SampleSourceStore";
import { modeContext } from '../../../Contexts/contexts';
import { getSampleSources } from '../../../../utils/API/api_sampleSource';
import AddSampleSourceModal from './forms/AddSampleSourceModal';
import DropdownComponent from '../../../Widgets/Dropdown/DropdownComponent'

const tableColumns = [
  {
    field: "name",
    name: "Название источника образца",
    showColumn: true,
    sort: true,
    type: "is_active",
  },
  {
    field: "description",
    name: "Описание",
    showColumn: true,
    sort: true,
    type: "string",
  },
  {
    field: "user_fields_count",
    name: "Поля пользователя",
    showColumn: true,
    sort: false,
    type: "string",
  },
  {
    field: "sources_count",
    name: "Всего",
    showColumn: true,
    sort: false,
    type: "string",
  },
  {
    field: "is_active",
    name: "Включено",
    showColumn: true,
    sort: false,
    type: "switch",
  },
  {
    field: "updated_at",
    name: "Дата последнего обновления",
    showColumn: true,
    sort: false,
    type: "date",
  },
  {
    field: "user_fields",
    systemColumn: true,
  },
];

const SampleSourcesGrid = observer(({ setWidget, sizeX, mode, type = null }) => {
  const storageStore = useContext(StorageStore);
  const { loadTree, selectedSample } = React.useContext(Store);
  const sampleSourceStore = useContext(SampleSourceStore);
  const modeMain = React.useContext(modeContext);

  const [countInPage, setCountInPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageAll, setPageAll] = useState(10);
  const [countAll, setCountAll] = useState(0);
  const [filter, setFilter] = useState('');
  const [sortField, setSortField] = useState({ type: '', field: '' });
  const [structHeader, setStructHeader] = useState({
    name: true,
    search: true,
    add: true,
    filter: true,
    print: false,
    wrap: true
  });
  const [isSearchBarcode, setIsSearchBarcode] = useState(false);

  const [rows, setRows] = useState([]);
  const [struct, setStruct] = useState([]);
  const [selectItem, setSelectItem] = useState();
  const [rowsButton, setRowsButton] = useState({
    print: false,
    copy: false,
    edit: true,
    delete: true
  });
  const [isLoadAfterError, setIsLoadAfterError] = useState(true);
  const [filterFieldsSave, setFilterFieldsSave] = useState();
  const [visiblePrintMenu, setVisiblePrintMenu] = useState(false);

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [isAddSuccess, setIsAddSuccess] = React.useState(false);
  const [typesSampleSource, setTypesSampleSource] = useState([]);


  useEffect(() => {
    loadTypes()
  }, [visibleAdd])

  useEffect(() => {
    setStruct(tableColumns);
  }, []);

  const rules = mode.rules ?? {};

  React.useEffect(() => {
    setStructHeader({
      name: true,
      search: true,
      add: rules?.sample?.upsert,
      filter: false,
      print: true,
      wrap: true
    });
    setRowsButton({
      edit: rules?.sample?.upsert,
      delete: rules?.sample?.delete,
      activate: true
    })
  }, [mode]);

  useEffect(() => {
    if (isLoadAfterError)
      loadTableData();
  }, [mode, filter, page, sortField])


  useEffect(() => {
    if (selectItem) {
      const loadSourceById = async () => {
        await sampleSourceStore.setSampleSourceId(selectItem.id, mode.token);
        setVisibleAdd(true);
      }
      loadSourceById();
    }
    else sampleSourceStore.clearSampleSourceField();
  }, [selectItem])

  const changeColumns = (newColumns) => {
    let mapedColumns = newColumns.filter(val => val.showColumn).map(val =>
      typeof val.field != 'string' ? val.name : val.field
    )
    let targetColumns = [...mapedColumns]
    loadTableData(targetColumns);
  }

  const loadTableData = async (columns = null, filterFields = null) => {
    const { result } = await getSampleSources(mode.token, page, {
      search: filter,
      sorting: sortField.type != '' ? JSON.stringify(sortField) : '',
      columns: columns
    });
    const items = result.data;
    const table = result.table;

    if (items) {
      items.map((item) => {
        item.user_fields.forEach((field) => {
          item[`user_field[${field.id}]`] = field.value;
        })
      });

      const formattedData = JSON.parse(JSON.stringify(items));
      items?.map((val, index) => {
        for (let key of Object.keys(val)) {
          try {
            formattedData[index][key] = JSON.parse(val[key])
          } catch {
            formattedData[index][key] = val[key]
          }
        }
      });
      setStruct(table);
      setCountAll(result.pagination.items_count);
      setRows(formattedData.map((row) => ({ id: row.id, setting: null, value: row })));
      setPageAll(result.pagination.pages_count);

    } else {
      setWidget({
        status: 'Error',
        text: result.error_code,
        fun: async () => {
          setIsLoadAfterError(false)
        }
      })
    }
  }

  const loadTypes = async () => {
    const { result } = await getTypeSampleSourcesList(mode.token);

    if (result.data) {
      setTypesSampleSource(result.data);
    } else {
      setWidget({
        status: 'Error',
        text: result.error_code,
        fun: async () => {
          setIsLoadAfterError(false)
        }
      })
    }
  }
  return (
    <div style={{ height: '100%' }}>
      <WorkRightHeader setVisibleAdd={setVisibleAdd}
        activeData={mode.type.item}
        struct={structHeader}
        setVisiblePrintMenu={setVisiblePrintMenu}
        filter={filter}
        setIsSearchBarcode={setIsSearchBarcode}
        isSearchBarcode={isSearchBarcode}
        setFilter={setFilter} />

      <div className={classes.workRightManual_countRows}>Всего найдено: {countAll}</div>

      <WorkRightSelectPage allCount={pageAll}
        selectItem={page}
        setSelectItem={setPage} />
      <WorkRightTable mode={mode}
        struct={struct}
        rows={rows}
        visiblePrintMenu={visiblePrintMenu}
        setVisiblePrintMenu={setVisiblePrintMenu}
        setRows={setRows}
        rowsButton={rowsButton}
        setVisibleAdd={setVisibleAdd}
        setSelectItem={setSelectItem}
        setWidget={setWidget}
        sortField={sortField}
        showColumnSelector={true}
        setSortField={setSortField}
        fromOther={true}
        page={page}
        loadTSamples={() => { }}
        showExpadedRow={true}
        changeColumns={changeColumns}
      />
      {
        visibleAdd && <AddSampleSourceModal
          setModalOpened={setVisibleAdd}
          mode={mode}
          typesSampleSource={typesSampleSource}
          isAddSuccess={isAddSuccess}
          setIsAddSuccess={setIsAddSuccess}
        />
      }
    </div>
  );
});

export default SampleSourcesGrid;
