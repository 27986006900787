import React, { useContext, useEffect, useState } from 'react';
import classes2 from "../WorkRight/workRight.module.css";
import classes from "./workRightTable.module.css"
import WorkRightTableRowItem from "./workRightTableRowItem";
import { getMasItem } from "../../../../utils/struct";
import { deleteReport, deleteTemplate } from "../../../../utils/API/api_report";
import { deleteUser } from "../../../../utils/API/api_user";
import { deleteSample, getSample, moveSample } from "../../../../utils/API/api_sample";
import { deleteDataCatalog } from "../../../../utils/API/api_catalog";
import { observer } from "mobx-react-lite";
import { SamplesStore } from "../../../../store/SamplesStore";
import ImagePopupComponent from "../../../Widgets/ImagePopup/ImagePopupComponent";
import { StorageStore } from "../../../../store/StorageStore";
import { ModeCopy } from "../../../Widgets/Modal/ModalMoveComponent";
import { Store } from "../../../../store/Store";
import { toJS } from "mobx";
import arrowSVG from "../../../../svg/arrow-01.svg";
import notificationSVG from "../../../../svg/notification.svg";
import dropStyles
  from "../../WorkRightStorageDir/WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css";
import { deleteTypeSampleSource, toggleActivateTypeSampleSource } from "../../../../utils/API/api_typeSampleSource";
import { deleteSampleSource, toggleActivateSampleSource } from "../../../../utils/API/api_sampleSource";



const WorkRightTableRow = observer(({
  mode,
  struct,
  rowsItem,
  rowsButton,
  setVisibleAdd,
  selectedItemsToPrint,
  selectedItemsToImport,
  selectItem,
  setSelectItem,
  setWidget,
  fromOther = false,
  handleSetImages,
  showColumnSelector = false,
  openPrintModal,
  typePackItems,
  changeTypePack,
  indexInData,
  selectClick,
  showExpadedRow = false
}) => {

  const { loadSampleById } = React.useContext(Store);
  const [massItem, setMassItem] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    if (showColumnSelector) {
      setMassItem(getMasItem(struct?.filter(i => i.showColumn), rowsItem.value))
    }
    else
      setMassItem(getMasItem(struct?.filter(i => i.showColumn), rowsItem.value))
  }, [struct, rowsItem])

  const startCopy = async () => {
    if (mode.path === 'Образцы' || mode.path === 'Архив' || mode.path === 'Образцы в хранилище') {
      await loadSampleById(mode.token, rowsItem.id);
      mode.setCopyMode(true);
      mode.setCopyType(ModeCopy);
    } else {
      mode.setCopyMode(true);
      mode.setCopyType(ModeCopy);
    }
  }

  const handleDeleteButton = () => {
    if (mode.path === 'Пользователи') {
      setWidget({
        status: 'Sure',
        text: 'Вы действительно хотите удалить пользователя?',
        fun: async () => {
          await deleteUser(mode.token, rowsItem.id);
          const m = { id: mode.type.item.id, name: mode.type.item.name }
          mode.setType({ mode: 'view', item: m })
        }
      })
    }
    if (mode.path === 'Отчеты') {
      setWidget({
        status: 'Sure',
        text: 'Вы действительно хотите удалить отчет?',
        fun: async () => {
          await deleteTemplate(mode.token, rowsItem.id);
          const m = { id: mode.type.item.id, name: mode.type.item.name }
          mode.setType({ mode: 'view', item: m })
        }
      })
    }
    if (mode.path === 'Образцы' || mode.path === 'Архив' || mode.path === 'Образцы в хранилище') {
      setWidget({
        status: 'Sure',
        text: 'Вы действительно хотите удалить образец?',
        fun: async () => {
          await deleteSample(mode.token, rowsItem.id);
          mode.setType({ mode: 'view', item: '' })
        }
      })
    }
    if (mode.path === 'Справочники') {
      setWidget({
        status: 'Sure',
        text: 'Вы действительно хотите удалить запись?',
        fun: async () => {
          await deleteDataCatalog(mode.token, rowsItem.id);
          mode.setType({ mode: 'view', item: mode.type?.item })
        }
      })
    }
    if (mode.path === 'Типы источников образцов') {
      setWidget({
        status: 'Sure',
        text: 'Вы действительно хотите удалить данный тип источника образца?',
        fun: async () => {
          await deleteTypeSampleSource(mode.token, rowsItem.id);
          mode.setType({ mode: 'view', item: '' })
        }
      })
    }
    if (mode.path === 'Источники образцов') {
      setWidget({
        status: 'Sure',
        text: 'Вы действительно хотите удалить данный источник образца?',
        fun: async () => {
          await deleteSampleSource(mode.token, rowsItem.id);
          mode.setType({ mode: 'view', item: '' })
        }
      })
    }
  }

  const handleActivateButton = () => {
    if (mode.path === 'Источники образцов') {
      setWidget({
        status: 'Sure',
        text: `Вы действительно хотите ${rowsItem.value.is_active ? 'выключить' : 'включить'} данный источник образца?`,
        fun: async () => {
          await toggleActivateSampleSource(mode.token, rowsItem.id);
          mode.setType({ mode: 'view', item: '' })
        }
      })
    }
    if (mode.path === 'Типы источников образцов') {
      setWidget({
        status: 'Sure',
        text: `Вы действительно хотите ${rowsItem.value.is_active ? 'выключить' : 'включить'} данный тип источника образца?`,
        fun: async () => {
          await toggleActivateTypeSampleSource(mode.token, rowsItem.id);
          mode.setType({ mode: 'view', item: '' })
        }
      })
    }
  }

  const Table = () => {
    return massItem.map((item, index) => {
      let it = item;
      return it ?
        <WorkRightTableRowItem
          mode={mode}
          selectItem={selectItem}
          selectClick={selectClick}
          selectedItemsToPrint={selectedItemsToPrint}
          selectedItemsToImport={selectedItemsToImport}
          rowsItem={rowsItem}
          changeTypePack={changeTypePack}
          item={it}
          typePackItems={typePackItems}
          key={index}
          index={indexInData}
          fromOther={fromOther}
          params={rowsItem.setting ? rowsItem.setting[it.name]
            : {}}
          imagePopup={() => {
            if (it.type === 'image') {
              if (it.setting)
                handleSetImages(rowsItem.setting);
            }
          }}
        /> : it.type
    })
  }

  const startPrint = () => {
    openPrintModal([{ id: rowsItem.id, barcode: rowsItem.value.barcode }])
  }

  return (
    <>
      <tr className={classes.workRightTableRow}>
        {showExpadedRow && <td className={classes.workRightTableItem + ' '}>
          <button
            onClick={() => { setIsExpanded(!isExpanded) }}
          >
            <img className={!isExpanded ? classes.rigthArrow : undefined} src={arrowSVG} alt="" />
          </button>
        </td>}
        <Table />
        <td className={classes.workRightTableItem + ' ' + classes.workRightTableItem_last}>
          <div style={{ display: 'flex' }}>
            {
              rowsButton.activate &&
              <div className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_activate} onClick={() => handleActivateButton()}></div>
            }
            {
              rowsButton.print &&
              <div className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_print} onClick={() => startPrint()}></div>
            }
            {(rowsButton.copy && !(rowsItem?.setting?.sample?.status == 'archived')) && <div onClick={startCopy} className={classes.workRightTableItem_Button + ' '
              + classes2.workRightTableItem_button_copy}></div>}
            {(rowsButton.edit && !(rowsItem?.setting?.sample?.status == 'archived')) && <div onClick={async () => {

              if (mode.path === 'Справочники') {
                let values = rowsItem.value

                let settingsValues = Object.values(rowsItem.setting)
                let settingsKeys = Object.keys(rowsItem.setting)

                const fields = settingsKeys.reduce((acc, key, index) => {
                  acc[key] = {
                    value: values[key],
                    setting: settingsValues[index],
                  }
                  return acc;
                }, {})
                const row = { id: rowsItem.id, fields }
                setSelectItem(row);
                setVisibleAdd(true);
              } else if (mode.path === 'Образцы' || mode.path === 'Архив' || mode.path === 'Образцы в хранилище') {
                await loadSampleById(mode.token, rowsItem.id);
                setVisibleAdd(true);
              } else if (mode.path === 'Типы источников образцов') {
                setSelectItem({ id: rowsItem.id });
              } else if (mode.path === 'Источники образцов') {
                setSelectItem({ id: rowsItem.id });
              }
              else {
                const row = { id: rowsItem.id, fields: rowsItem.value }
                setSelectItem(row);
                setVisibleAdd(true);
              }

            }}
              className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_edit}>
            </div>}
            {rowsButton.delete && <div onClick={handleDeleteButton}
              className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_delete}></div>}
          </div>
        </td>
      </tr>
      {showExpadedRow && isExpanded && <tr className={classes.expandedBody}>
        <td colSpan={massItem.length + 1}>
          {
            (mode.path === 'Типы источников образцов') &&
            <div style={{ padding: '10px 57px' }}>
              <div style={{ fontSize: '16px', margin: '0 0 5px 0' }}>Пользовательские поля:</div>
              {rowsItem.value.user_fields?.length ?
                rowsItem.value.user_fields?.map((field, index) => <div
                  key={index} className={classes.workRightTableExpandedRowUserfieldItem}>{field.name}</div>)
                : <div className={classes.workRightTableExpandedRowUserfieldItem}>Нет данных</div>
              }
            </div>
          }
          {
            (mode.path === 'Источники образцов') &&
            <div style={{ padding: '10px 57px', display: 'grid', gridTemplateColumns: '50% 50%' }}>
              <div>
                <div style={{ fontSize: '16px', margin: '0 0 5px 0' }}>Пользовательские поля:</div>
                {rowsItem.value.user_fields?.length ?
                  rowsItem.value.user_fields?.map((field, index) => <div
                    key={index} className={classes.workRightTableExpandedRowUserfieldItem}>{field.name}</div>)
                  : <div className={classes.workRightTableExpandedRowUserfieldItem}>Нет данных</div>
                }
              </div>
              <div>
                <div style={{ fontSize: '16px', margin: '0 0 5px 0' }}>Значение:</div>
                {rowsItem.value.user_fields?.length ?
                  rowsItem.value.user_fields?.map((field, index) => <div
                    key={index} className={classes.workRightTableExpandedRowUserfieldItem}>{field.value ? JSON.parse(field.value)?.value : ""}</div>)
                  : <div className={classes.workRightTableExpandedRowUserfieldItem}>Нет данных</div>
                }
              </div>
            </div>
          }
        </td>
        <td></td>
      </tr>}
    </>
  );
});

export default WorkRightTableRow;
