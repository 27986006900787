// Autocomplete.js
import React, { useEffect, useState } from 'react';
import './Autocomplete.css';

const Autocomplete = ({
  items,
  keyItem = 'id',
  valueItem = 'name',
  onChange,
  onSelect,
  selected
}) => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selected) {
      setInputValue(selected[valueItem]);
    }
  }, [selected])

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    setFilteredItems(items);

    event.stopPropagation()
    if (event.target.value.length < 3) {
      return;
    }
    onChange(inputValue);
  };

  const handleSelect = (value) => {
    setInputValue(value[valueItem]);
    setFilteredItems([]);
    onSelect(value[keyItem]);
  };

  return (
    <div className="autocomplete-container">
      <input
        className="autocomplete-input"
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Введите название..."
      />
      <ul className="autocomplete-suggestions">
        {filteredItems?.map((item, index) => (
          <li key={index} className="autocomplete-suggestion" onClick={() => handleSelect(item)}>
            {item[valueItem]}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Autocomplete;
